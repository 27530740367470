import React from 'react'
import { Col, Row } from 'reactstrap'

const HomeNewPatients = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1'>
            <Row className='justify-content-center align-items-center py-lg-5 py-4 gy-4'>
                <Col md='11' xl='6'>
                    <div>
                        <h1 className='fs-ss-24 text-raleway text-ss-primary fw-700'>
                            Pediatrics Clinic In Guntersville,
                        </h1>
                    </div>
                    <div className='pb-md-3 pt-md-0 pb-3'>
                        <h3 className='fs-ss-36 text-raleway fw-800'>
                            Accepting New Patients
                        </h3>
                    </div>
                    <div className='pe-md-5'>
                        <p className='fs-ss-16-2 text-roboto lh-1-8'>
                            It is difficult to find an excellent pediatric clinic in Guntersville that has the ability to take new children onboard; we get it. Hence why we’ve expanded our service capacity to include more patients. We endeavor to offer valuable patient resources, convenient office hours, exceptional support, as well as outstanding expertise. Moreover, we maintain the quality of care your little ones receive whether they join us for the first time or are regulars at the office.
                        </p>
                    </div>
                </Col>

                <Col md='11' xl='6'>
                    <div className='pt-md-0 pt-4'>
                        <img src='home/Home-page-pic2-v2.1.webp' alt='Little girl sitting and speaking to her healthcare provider at our pediatrics clinic in Guntersville' width='100%' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default HomeNewPatients