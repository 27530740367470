import React from 'react'
import { Table } from 'reactstrap'

const WellVisitInfo = () => {
  return (
    <div id='website-responsive'>
    <div className='left-padding7rem1 right-padding7rem1'>
        <Table striped bordered>
            <tbody className='' style={{ textAlign:'center' }}>
                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>1 MONTH</td>
                    <td className='fs-ss-14-1 px-m-4 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSICAL EXAM, NEWBORN SCREENING TEST (HEEL STICK)</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>2 MONTHS</td>
                    <td className='fs-ss-14-1 px-d-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, IMMUNIZATIONS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>4 MONTHS</td>
                    <td className='fs-ss-14-1 px-d-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, IMMUNIZATIONS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>6 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, IMMUNIZATIONS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>9 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSICAL EXAM, ASQ DEVELOPMENTAL SCREENING ASSESSMENT,
                    <br/>
                    CATCH-UP IMMUNIZATIONS IF NEEDED
                    </td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>12 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, IMMUNIZATIONS, LEAD AND HEMOGLOBIN LEVEL (FINGER STICK)</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>15 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, IMMUNIZATIONS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>18 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, IMMUNIZATIONS,
                    <br/>ASQ AND MCHAT DEVELOPMENTAL SCREENING ASSESSMENTS
                    </td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>2 YEARS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT, WEIGHT AND HEAD CIRCUMFRENCE MEASURED, PHYSIAL EXAM, CATCH-UP IMMUNIZATIONS IF NEEDED, ASQ AND MCHAT DEVELOPMENTAL SCREENING ASSESSMENTS, LEAD AND HEMOGLOBIN CHECK (FINGER STICK)
                    </td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>3 YEARS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT AND WEIGHT MEASURED, PHYSICAL EXAM, CATCH-UP IMMUNIZATIONS IF NEEDED</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>4 YEARS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT AND WEIGHT MEASURED, PHYSICAL EXAM, IMMUNIZATIONS, ASQ DEVELOPMENTAL SCREENING ASSESSMENT</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>5-10 YEARS</td>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>HEIGHT AND WEIGHT MEASURED, VISION AND HEARING SCREENING AS INDICATED, PHYSICAL EXAM, CATCH-UP IMMUNIZATIONS IF NEEDED</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>11 YEARS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT AND WEIGHT MEASURED, PHYSICAL EXAM, VISION AND HEARING SCREENING AS INDICATED, IMMUNIZATIONS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>12-15 YEARS</td>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>HEIGHT AND WEIGHT MEASURED, PHYSICAL EXAM, VISION AND HEARING SCREENING AS INDICATED, CATCH-UP IMMUNIZATIONS IF NEEDED</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>16 YEARS</td>
                    <td className='fs-ss-14-1 px-md-5 py-2 '>HEIGHT AND WEIGHT MEASURED, PHYSICAL EXAM, HEARING AND VISION SCREENING AS INDICATED, IMMUNIZATIONS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>17-18 YEARS</td>
                    <td className='fs-ss-14-1 px-md-4 py-2 '>HEIGHT AND WEIGHT MEASURED, PHYSICAL EXAM, VISION AND HEARING SCREENING AS INDICATED, CATCH-UP IMMUNIZATIONS IF NEEDED</td>
                </tr>
            </tbody>
        </Table>
        </div>
    </div>
  )
}

export default WellVisitInfo