import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'

const PsychiatryMindAndBody = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img  px-4 px-md-0' style={{ backgroundImage:'url("/psych-visit/Mental-Health-Middle-Section-bg-v1.png")', height:'100%', width:'100%' }}>
            <div className='p-top-bottom2rem4'>
            <div className='pb-5 pb-md-5'>
                <center>
                    <img src='/psych-visit/Mental-Health-Middle-Section-layer-v1-1024x230.png' alt='Mind & Body' width='' height='auto' className='img-w12' />
                </center>
            </div>

            <div className='pb-md-5 pb-5 px-md-4'>
                <h3 className='fs-ss-32 text-raleway fw-700 lh-1'>
                    <center>
                        Let us help maintain your mind for you and your little ones!
                    </center>
                </h3>
            </div>

            <div className='pt-2'>
                <a href='tel:+12562021111'>
                <center>
                    <Button className='btn-ss-primary rounded30 btn-hover flex-center' style={{ padding:'6px 30px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)' }}>
                        <div className='flex-center'>
                            <TelephoneFill className='me-2 fs-ss-22 text-roboto' />
                            <text className='fs-ss-22 text-roboto fw-600'>(256) 202 1111</text>
                        </div>
                    </Button>
                </center>
                </a>
            </div>
        </div>
        </div> 
    </div>
  )
}

export default PsychiatryMindAndBody