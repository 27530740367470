import React, { useEffect } from 'react'
import PatientFormMain from '../components/patient-form/PatientFormMain'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import PatientFormEnsuring from '../components/patient-form/PatientFormEnsuring'
import PatientFormPdfs from '../components/patient-form/PatientFormPdfs'
import PatientFormCallNow from '../components/patient-form/PatientFormCallNow'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const PatientFormPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>   
        <HelmetProvider>
            <Helmet>
            <title>All the Pediatric Patient Forms You Need &ndash; Valley Pediatrics</title>
            <meta name="description" content="Here at Valley Pediatrics, we make caring for your children easier by keeping all the pediatric patient forms you need on the same page." />
            <link rel="canonical" href="https://www.valley-pediatrics.com/patients-forms/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="All the Pediatric Patient Forms You Need &ndash; Valley Pediatrics" />
            <meta property="og:description" content="Here at Valley Pediatrics, we make caring for your children easier by keeping all the pediatric patient forms you need on the same page." />
            <meta property="og:url" content="https://www.valley-pediatrics.com/patients-forms/" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2023-01-11T10:35:50+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:type" content="image/png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="1 minute" />
            </Helmet>
        </HelmetProvider>
        <TopHeader/>
        <MainHeader/>

        <PatientFormMain/>
        <PatientFormEnsuring/>
        <PatientFormPdfs/>
        <PatientFormCallNow/>
    
        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default PatientFormPage