import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/boaz/Valley-Pediatrics-Sick-Visits-pic-v1.webp'

const BoazSickVisits = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <Row className=''>
                <Col md='11' xl='8' className=''>
                    <div className=''>
                        <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                            Sick Visits Boaz, Al
                        </text>
                    </div>

                    <div className='py-2'>
                        <text className='fs-ss-22-2 fw-700 text-raleway text-ss-black lh-1-2'>
                            We will be there for you
                        </text>
                    </div>


                    <div className='py-2'>
                        <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black '>
                            We know that living in Boaz is kind of hard as it remains cold most of the time, kids get sick quite often, and we will be there for them when they need us because we care. Sometimes it can be difficult to decide whether your child’s condition requires a visit to the doctor or not. We suggest you trust your instincts, and whenever in doubt, go for a pediatric sick visit. Our team of expert practitioners will help to treat your sick child by evaluating their symptoms, like rashes, sore throats, ear infections, etc. Do notice that some symptoms need to be addressed immediately, so don’t delay.
                        </text>
                    </div>
                   
                    <div className=''>
                        <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            Call us or come for a visit if your child has any of the conditions below or something out of routine:
                        </text>
                        <ul style={{ padding:'16px', listStyleType:'square'}} className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            <li className=''>A fever between 104.0°F and 105.0°F</li>
                            <li className=''>A fever lasting longer than three days in a row, whether or not there is a clear source of infection</li>
                            <li className=''>The presence of a fever and a sore throat for more than 24 to 48 hours</li>
                            <li className=''>Your child has difficulty breathing, a rash, is lethargic, won’t eat, or has other symptoms</li>
                            <li className=''>A dry mouth, less wet diapers, or increased urine frequency are symptoms of dehydration in your child</li>
                        </ul>
                    </div>
                </Col>

                <Col md='11' xl='4' className=''>
                    <div className='pt-md-5 pt-'>
                        <img src={Img1} alt='Sick Visit Boaz' height='auto' width='100%' className=''  />
                    </div>
                </Col>
            </Row>

            <div className='pt-5'>
                <div className='pb-md-3 pb-2'>
                    <text className='fs-ss-28-2 text-raleway text-ss-black fw-700 lh-1-2'>
                        What to Expect at Your Clinic Visit
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Your healthcare professional will enquire about your child’s symptoms, the health history of your family, and any other information that can be useful in determining your child’s condition. Next would be a physical examination, which may include taking the child’s temperature, monitoring their breathing and heartbeat, and looking for any indications of an infection.
                    </text>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BoazSickVisits