import React from 'react'
import { Col, Row } from 'reactstrap'

const PsychiatryHolisticApproach = () => {
  return (
    <div id='website-responsive'>
        <div className='pb-md-4 p-top-bottom2rem'>
        <Row className='pb-md-5 pb-4 left-padding6rem right-padding6rem'>
                    <Col md='11' xl='7' className='m'>
                        <div className='pb-2'>
                            <h2 className='fs-ss-32 text-raleway fw-700 text-ss-primary lh-1'>
                                A Holistic Approach to Behavioral Health
                                {/* <hr className='hr-style' style={{ width:'60px', marginTop:'0px', marginBottom:'4px'}}/> */}
                            </h2>
                        </div>

                        {/* <div className='pb-3'>
                            <text className='text-raleway fw-600 fs-ss-24-1'>   
                                Mental Health Nurse Practitioner
                            </text>
                        </div> */}

                        <div style={{ textAlign:'justify'}}>
                            {/* <text className='fs-ss-16-2 text-roboto lh-1-8'> */}
                                <div className='pb1'>
                                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                                We understand that each child and family have unique needs, so we offer personalized assessments and treatment plans tailored to meet those specific needs. Our team takes a family-centered approach, which means we involve parents and caregivers in the treatment process and work together to achieve the best possible outcomes for each child.
                                </p>
                                </div>
                                <div className='pb1'>
                                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                                Our services include evaluations, counseling, and medication management for various behavioral and emotional issues such as anxiety, depression, ADHD, bipolar disorder, oppositional defiant disorder, and more. We also offer specialized services for children with developmental and learning disabilities, such as autism spectrum disorder.
                                </p>
                                </div>
                                <div className=''>
                                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                                    Our mission is to consistently maintain the highest service delivery standards for psycho-social recovery. Remember, a crucial part of moving towards wellness is finding providers who support you and make you feel comfortable through your recovery journey.
                                </p>
                                </div>
                            {/* </text> */}
                        </div>
                    </Col>

                    <Col xs='9' xl='5' className='pt-md-0 pt-0 mx-auto' style={{ display:'flex', justifyContent:'center', alignItems:'baseline'}}>
                        <img src='/psych-visit/Valley-Psychiatry-pic2-v1.webp' alt='Providers' width='' height='auto' className='about-p-img1 img-w5' />
                    </Col>
                </Row>
        </div>
    </div>
  )
}

export default PsychiatryHolisticApproach