import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'
import Img1 from '../../../assets/images/boaz/Pediatrics-Clinic-Boaz-Middle-Section-bg-v1.webp'

const BoazGateway = () => {
  return (
    <div id='website-responsive' className='' style={{ backgroundImage:`url(${Img1})` , backgroundPosition:'bottom center', backgroundSize:'cover', height:'100%', width:'100%'}}>
        <div className='py-5'>
            <div className='pb-3 pt-md-3'>
                <text className='fs-ss-40-2 text-raleway text-white lh-1-2 fw-700 ' style={{ textShadow:'0 0 10px rgba(0,0,0,.3)'}}>
                    <center>
                        Your Gateway To Special Care
                    </center>
                </text>
            </div>

            <div className='pb-3'>
                <text className='fs-ss-28-4 text-montserrat text-ss-primary fw-700'>
                    <center>
                        Mon - Fri (8:30AM - 5:00PM)
                    </center>
                </text>
            </div>

            <div className='pt-4 pb-md-3'>
                <center>
                <a href='tel:+12562021111'>
                <Button className='btn-ss-prima' style={{ border:'none', borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)', padding:'8px 30px', backgroundColor:'white'}}>
                    <div className='flex-center'>
                        <TelephoneFill className='me-2 fs-ss-22 text-ss-primary' />
                        <text className='fs-ss-22 text-roboto fw-600 text-ss-primary'>
                            (256) 202-1111
                        </text>
                    </div>
                </Button>
                </a>
                </center>
            </div>
        </div>
    </div>
  )
}

export default BoazGateway