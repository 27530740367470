import React from 'react'
import { ChevronCompactDown, ChevronDoubleRight, ChevronRight, Facebook, GeoAltFill, Instagram,  PhoneFill, TelephoneFill } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Table } from 'reactstrap'
import Logo from '../../assets/images/valley-pediatrics-logo-1024x392.png'

const Footer = () => {
  return (
    <div id='website-responsive' style={{ backgroundImage:'url("/footer/Footer-Background.png")', backgroundSize:'cover' , backgroundPosition:'bottom center' ,backgroundRepeat:'no-repeat', backgroundColor:'black',  height:'100%', width:'100%', }}>
        <Row>
            <Col xs='12' sm='12' md='4' style={{ marginTop:'5.2rem'}}>
                <div style={{ width:'100%', display:'flex', justifyContent:'center', marginRight:'1.3rem', marginBottom:'3rem'   }}>
                     <img src={Logo} alt='logo' height='auto' width='200px' />
                </div>
                <div style={{ width:'100%', display:'flex', justifyContent:'center', marginBottom:'1.2rem'  }}>

                <a style={{ textDecoration:'none'}} href='tel:+12562021111' className=' fs-ss-17 ' >
                <div className='flex-center'>   
                <Button className='btn-hover' style={{padding:'12px 24px', marginBottom:'12px' , width:'', backgroundColor:'#64CBCC', border:'none', borderRadius:'0px', boxShadow:'0 4px 10px 0 rgba(0,0,0,.3)'}}>
                    <div className='flex-center'>
                    <TelephoneFill className='fs-ss-18'/>
                    <text className='fs-ss-18 text-roboto fw-500'>&nbsp; (256) 202 1111 </text>
                    </div>
                </Button>
                </div>
                </a>
                </div>

                <div style={{ width:'100%', display:'flex', justifyContent:'center', marginRight:'1.3rem'   }}>
                    <a style={{ textDecoration:'none'}} target='_blank' href='https://www.facebook.com/valleypediatrics/'><Facebook style={{ fill:'#FF379F', fontSize:'1.8rem', marginRight:'1rem' }}/> </a>

                    <a style={{ textDecoration:'none'}} target='_blank' href='https://www.instagram.com/valleypediatrics1/'><Instagram style={{ fill:'#FF379F', fontSize:'1.8rem' }}/> </a>

                </div>

            </Col>

            <Col xs='12' md='6' lg='4' className='padding3xem ps-5 pe-5 mt-4' style={{ marginTop:'',marginBottom:''}}>
                <div style={{ marginBottom:'0.4rem'}}>
                <h4 className='text-ss-primary fw-700 text-raleway' style={{ fontSize:'26px' }}>Clinic Hours</h4>
                </div>
                <div>
                    <Table striped style={{ backgroundColor:'none'}}>
                        <tbody className=''>
                            <tr>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>Monday</td>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>Tuesday</td>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>Wedndesday</td>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>Thursday</td>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>Friday</td>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black',}}>Sat - Sun</td>
                                <td className='fs-ss-15 text-roboto' style={{ background:'none', color:'black',}}>Closed</td>
                            </tr>
                        </tbody>
                    </Table>

                </div>
            </Col>

            <Col xs='12' md='6' lg='4' className='padding3xem ps-5 mt-4' style={{ marginTop:'', paddingLeft:''}}>
                <div><h4 className='text-ss-primary fw-700 text-raleway' style={{ fontSize:'26px' }}>Contact Details</h4></div>
                
                
                <div className='my-2'>
                    <a style={{ textDecoration:'none'}} href='tel:+12562021111'>
                        <TelephoneFill className='text-ss-secondary'/>
                        <span className='fs-ss-15 text-black text-roboto' style={{  marginLeft:'10px'}}>(256) 202 1111</span>
                    </a>   
                </div>


                <div className='my-2'>
                    <a rel='noreferrer' style={{ textDecoration:'none'}} href='https://www.google.com/maps/place/Valley+Medical/@34.3454038,-86.3068594,17z/data=!4m2!3m1!1s0x8889f934d22a25b9:0x88839cfbc9bb566c' target='_blank' >
                        {/* <div className='flex-center'> */}
                        {/* <div> */}
                        <GeoAltFill className='text-ss-secondary'/>
                        {/* </div> */}
                        {/* <div style={{ maxWidth:'320px'}}> */}
                        <span className='fs-ss-15 text-black text-roboto' style={{  marginLeft:'10px'}}>1436 Gunter Ave, Guntersville, AL 35976, US</span>
                        {/* </div> */}
                        {/* </div> */}
                    </a>
                </div>
<br/><br/>


    <div>
        <h4 className='text-ss-primary fs-ss-26 fw-700 text-raleway' style={{ fontSize:'26px' }}>Quick Links</h4></div>
                
                <div className='my-2 text-ss-secondary'><ChevronDoubleRight style={{ fontSize:'1rem', marginRight:'5px'}}/><Link style={{ textDecoration:'none'}} to='/blog'><text className='fs-ss-16 text-roboto text-black' style={{ color:'white',  fontSize:''}}>Blog </text></Link></div>


                <div className='my-2 text-ss-secondary'><ChevronDoubleRight style={{ fontSize:'1rem', marginRight:'5px'}}/><Link style={{ textDecoration:'none'}} to='/about'><text className='fs-ss-16 text-roboto text-black'  style={{ color:'white',  fontSize:''}}>About Us</text></Link></div>

                <div className='my-2 text-ss-secondary'><ChevronDoubleRight style={{ fontSize:'1rem', marginRight:'5px'}}/><Link style={{ textDecoration:'none'}} to='/sick-visits'><text className='fs-ss-16 text-roboto text-black'  style={{ color:'white',  fontSize:''}}>Sick Child Visits</text></Link></div>
            </Col>
        </Row>
    </div>
  )
}

export default Footer