import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/ads/Pediatric-Clinic-near-Albertville-pic4.webp'

const AlbertvilleExceptionalCare = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <Row>
                <Col md='7' className=''>
                    <div className=''>
                        <div className='py-3'>
                            <text className='fs-ss-30 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                The Path to Exceptional Care is Open to You
                            </text>
                        </div>

                        <div className=''>
                            <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            The medical professionals at our pediatric walk-in clinic are skilled in providing a variety of healthcare services, including:

                                <ul className='' style={{ padding:'16px', listStyleType:'square'}}>
                                    <li className=''>Administering recommended childhood immunization</li>
                                    <li className=''>Performing  newborn checkups, well-childcare, same-day sick visits, and sports physicals</li>
                                    <li className=''>Diagnosing and treatment of acute illnesses and injuries</li>
                                    <li className=''>Educating children and parents about the requirements of a healthy lifestyle and disease prevention, including proper nutrition, exercise, and sleep habits</li>
                                    <li className=''>Developmental screening by monitoring children’s milestones and signs of developmental delays</li>
                                    <li className=''>Evaluating behavioral development; guiding children and families how to control symptoms and minimize risk factors</li>
                                </ul>
                            </text>
                        </div>
                    </div>
                </Col>

                <Col md='5' className='pt-md-3'>
                    <div className='pt-md-5'>
                        <img src={Img1} alt='We are here' height='auto' width='100%' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default AlbertvilleExceptionalCare