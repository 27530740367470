import React from 'react'

const BlogsMain = () => {
  return (
    <div id='website-responsive'>
    <div className='bg-img3 about-text-padding' style={{ backgroundImage: 'url("/blogs/Blog-Banner-v1.webp")', height:'100%', width:'100%' }}>
        <div className='about-max-width'>
            <h1 className='fs-ss-44 text-oswald fw-800 lh-1 text-black'>
                Valley Pediatrics Blog
            </h1>
         </div>
    </div>
</div>
  )
}

export default BlogsMain