import React from 'react'

const DoseChartsMain = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img3 well-visit-text-padding' style={{ backgroundImage: 'url("/dose-chart/Dose-Chart-Banner.webp")', height:'100%', width:'100%' }}>
            <div className='about-max-width'>
                <h1 style={{ }} className='fs-ss-50 text-oswald fw-800 lh-1 text-black'>
                    Dose Charts
                </h1>
             </div>
        </div>
    </div>
  )
}

export default DoseChartsMain