import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import InsAndFinMain from '../components/insurance-finacial-info/InsAndFinMain'
import InsAndFinEnsuringHealth from '../components/insurance-finacial-info/InsAndFinEnsuringHealth'
import InsAndFinPrice from '../components/insurance-finacial-info/InsAndFinPrice'
import InsAndFinCallNow from '../components/insurance-finacial-info/InsAndFinCallNow'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const InsuranceAndFinancialPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>
        <HelmetProvider>
            <Helmet>
            <title>Pediatric Insurance and Financial Information &ndash; Valley Pediatrics</title>
            <meta name="description" content="Here at Valley Pediatrics, we ensure that healthcare is accessible for all children. View our insurance and financial information for more info!" />
            <link rel="canonical" href="https://www.valley-pediatrics.com/insurance-and-financial-information/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Pediatric Insurance and Financial Information &ndash; Valley Pediatrics" />
            <meta property="og:description" content="Here at Valley Pediatrics, we ensure that healthcare is accessible for all children. View our insurance and financial information for more info!" />
            <meta property="og:url" content="https://www.valley-pediatrics.com/insurance-and-financial-information/" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2022-04-18T05:12:43+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:type" content="image/png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="1 minute" />
            </Helmet>
        </HelmetProvider>
        <TopHeader/>
        <MainHeader/>

        <InsAndFinMain/>
        <InsAndFinEnsuringHealth/>
        <InsAndFinPrice/>
        <InsAndFinCallNow/>


        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default InsuranceAndFinancialPage