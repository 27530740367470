import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/ads/Pediatric-Clinic-near-Albertville-pic3.webp'

const ALbertvilleValleyPsychiatry = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <div className=''>
                <Row>
                    <Col md='8' className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
                        <div className='pe-md-5'>
                            <div className=''>
                                <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                    Valley Psychiatry Near Albertville
                                </text>
                            </div>

                            <div className='py-2 '>
                                <text className='fs-ss-22-2 fw-700 text-raleway text-ss-black lh-1-2'>
                                    Delivering Care To Heal
                                </text>
                            </div>

                            <div className='py-2 pe-md-5'>
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    Everyone experiences times of stress, sadness, grief, and conflict, and as you progress through different life phases of growth, you might require more support. More than others, certain people might go through one or more painful and challenging life transition phases. These imbalances can cause emotional and behavioral disruptions in your family, which can have a major detrimental effect.
                                </text>
                            </div>

                            <div className='pe-md-5' >
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    Throughout these trying times, have faith. There is assistance available! It suggests that in order to solve the issues, a professional team effort is necessary. The care team at Valley Pediatric Therapy Clinic near Albertville consists of mental health specialists who provide counselling to patients in order to help them overcome a range of difficulties that they could run into on a daily basis.
                                </text>
                            </div>

                            <div className='pe-md-5' >
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    We offer comprehensive psychiatric care through teamwork for mental and mood problems like OCD, PTSD, ADHD, Bipolar Disorder, and Anxiety. While understanding their medical needs and requirements, we ensure that we treat adults, teenagers, and children according to their age.
                                </text>
                            </div>
                        </div>
                    </Col>

                    <Col md='4' className='pt-md-3' style={{ display:'flex', justifyContent:'end'}}>
                        <div className='pt-md-5'>
                            <img src={Img1} alt='Valley' height='auto' width='100%' />
                        </div>
                    </Col>
                </Row>

                <div className='pt-4 pt-md-0'>
                <div className='pb-md-3 pb-2'>
                    <text className='fs-ss-28-2 text-raleway text-ss-black fw-700 lh-1-2'>
                        How it works?
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        If your child is showing signs of a mental health disorder, such as an inability to control emotions, deviations from age-appropriate thinking or behavior, persistent sadness, changes in sleeping or eating patterns, or a decline in academic performance, it is time to seek professional care as soon as you can.
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Get in touch with Valley Psychiatry for a child’s evaluation, diagnosis, intervention, and treatment plan with their parents or guardians.
                    </text>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ALbertvilleValleyPsychiatry