import React from 'react'
import Img1 from '../../../assets/images/arab/Pediatrics-Clinic-near-Arab-Banner-v1.webp'

const ArabMain = () => {
  return (
    <div id='website-responsive' className='bg-img ' style={{ backgroundImage:`url(${Img1})` ,height:'', width:'100%'}}>
    <div className='boaz-text-padding bg-img-h'>
    <div className='boaz-max-width'>
            <text className='fs-ss-44 text-oswald fw-800 lh-1-2 text-ss-black'>
                Pediatric Clinic Near Arab
            </text>
        </div>
    </div>
</div>
  )
}

export default ArabMain