import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/arab/Pediatric-Clinic-near-Arab-pic4-v1.webp'

const ArabExceptionalCare = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <Row>
                <Col md='7' className=''>
                    <div className=''>
                        <div className='py-3'>
                            <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                Your Doorway To Exceptional Care
                            </text>
                        </div>

                        <div className=''>
                            <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            Our pediatric walk-in clinic’s doctors are experts in offering a wide range of healthcare treatments, such as:

                                <ul className='' style={{ padding:'16px', listStyleType:'square'}}>
                                    <li className=''>Administering the advised childhood vaccines</li>
                                    <li className=''>Conducting sports physicals, well-child exams, same-day sick visits, and newborn examinations</li>
                                    <li className=''>Acute illness and injury diagnosis and treatment</li>
                                    <li className=''>Teaching young people and their parents about the needs of a healthy lifestyle and disease prevention, such as appropriate eating, exercise, and sleeping practices</li>
                                    <li className=''>Developmental screening through observation of children's developmental milestones and symptoms of delays</li>
                                    <li className=''>Assessing the development of behavior; advising kids and families on how to manage symptoms and reduce risk factors</li>
                                </ul>
                            </text>
                        </div>
                    </div>
                </Col>

                <Col md='5' className='pt-md-3'>
                    <div className='pt-md-5'>
                        <img src={Img1} alt='We are here' height='auto' width='100%' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default ArabExceptionalCare