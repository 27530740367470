import React from 'react'
import { Col, Row } from 'reactstrap'

const VaccineScheduleHealthyLife = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem p-top-bottom2rem2'>
            <Row className='gy-5'>
                <Col md='11' xl='6'>
                    <div className='pb-3'>
                        <h2 className='fs-ss-28-3 text-raleway fw-700 text-ss-primary lh-1-2'>
                            Giving Your Child The Best Shot At A Healthy Life
                        </h2>
                    </div>

                    <div className='pe-md-5 pe-0 pb-4 pb-md-0'>
                        <text className='fs-ss-16-2 text-roboto lh-1-8'>
                            All of us at Valley Pediatrics believe that prevention is the first step in the journey towards a cure. We strongly endorse the practice of ensuring that you follow the recommended pediatric vaccine schedule for your children as determined by the Center for Disease Control and Prevention as well as the American Academy of Pediatrics. We believe that vaccines are effective and safe for children and can prevent serious illness in the long run.
                        </text>
                        <div className='pt-2 pe-0'>
                        <text className='fs-ss-16-2 text-roboto lh-1-8'>
                            We also acknowledge that every parent has a right to choose their child’s healthcare. Therefore, our providers are always happy to discuss any issues or address any concerns you may express regarding the vaccination of your little one. Valley Pediatrics is willing to make concessions for any delays or spacing of vaccination for children. However, the clinic does not accept patients who are not planning to get vaccinated. As per our policy, we cannot make any recommendations for pediatric healthcare providers who would accept unvaccinated individuals that do not intend to be vaccinated.
                        </text> 
                        </div>
                    </div>
                </Col>

                <Col sm="8" md='11' xl='6' className='p-md-0 pt-2 pt-md-0'>
                    <div className='p-md-0'>
                        <img src='/vaccine/vacination-image-1024x1024.jpg' alt='Healthy Life' width='100%' height='auto' style={{ border:'6px solid #ff379f'}} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default VaccineScheduleHealthyLife