
import React from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";

const MainHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <section id='website-responsive' className='' style={{ width:'100%', position:'', boxShadow:'0px 0px 10px 0px rgba(0, 0, 0, 0.25)', marginBottom:'' }}>
    {/* Upper Header */}
    
    <div style={{ position:'' }} className='container-fluid font-pri bg-white px-lg-5'>
    <div className='row col-font py-2'>
    <div className='col-12 col-lg-4 d-none d-lg-block fw-600 font-upper-heading'>
</div>


    </div>
</div>

{/* Header Starts From Here */}
<div  className='container-fluid px-0 bg-pri px-xl-5 ' style={{ position:'', backgroundColor:'white' }} >
<nav className="navbar navbar-expand-lg  py-1 bg-white" style={{ position:'',  }}>
  <Link className="navbar-brand bg-white px-2 ms-xl-5" to="/"> 
  <img className="header-logo" alt="logo " id="logo" src='/logo/Valley-Pediatrics-Header-Logo.webp' width='200px' style={{ marginTop:'-10px' }} /> </Link>
    <button className="navbar-toggler bg-white border-0 rounded-0 me-4 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mx-auto top-border ps-xl-5 py-md-4">
        <li className="nav-item bottom-border p-left-header text-ss-black active text-raleway fs-ss-16 fw-700 text-hover-pri">
          <Link className={splitLocation[1] === "" ? "nav-link text-ss-primary active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold" : "nav-link text-ss-black active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold"} to="/">Home</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item bottom-border p-left-header text-ss-black text-raleway fs-ss-16 fw-700 text-hover-pri">
          <Link className={splitLocation[1] === "about" ? "nav-link text-ss-primary active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold" : "nav-link text-ss-black active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold"} to="/about">About Us</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item bottom-border right-menu text-ss-black text-raleway fs-ss-16 fw-700 text-hover-pri">
          <span  className="nav-link text-black p-left-header dropdown-toggle text-hover-pri">
            Our Services
          </span>
          <ul className="dropdown-menu hover1 text-ss-black text-raleway fs-ss-16 fw-700 py-3">
          <li className='text-ss-black text-raleway fs-ss-15 fw-600 text-hover-pri'>
          <Link className={splitLocation[1] === "sick-visits" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/sick-visits">Sick Visits</Link>
          </li>
            <li className='text-ss-black text-raleway fs-ss-15 fw-600 text-hover-pri py-3'  style={{ maxWidth:'' }}>
            <Link className={splitLocation[1] === "well-visits" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/well-visits">Well Visits and Immunizations</Link>
            </li>

            <li className='text-ss-black text-raleway fs-ss-15 fw-600 text-hover-pri'>
            <Link className={splitLocation[1] === "valley-psychiatry" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/valley-psychiatry">Valley Psychiatry</Link></li>
          </ul>
        </li>
        <li className="nav-item bottom-border p-left-header text-ss-black text-raleway fs-ss-16 fw-700">
          <Link className={splitLocation[1] === "patients-forms" ? "nav-link text-ss-primary active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold" : "nav-link text-ss-black active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold"} to="/patients-forms">Patient Forms</Link>
        </li><span className='px-md-2'/>
		<li className="nav-item bottom-border right-menu text-ss-black text-raleway fs-ss-16 fw-700">
          <span  className="nav-link text-black p-left-header dropdown-toggle text-raleway fs-ss-16 fw-600 text-hover-pri">
           Parent Resources
          </span>
          <ul className="dropdown-menu hover1 py-3">
          <li>
          <Link className={splitLocation[1] === "dose-charts" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/dose-charts">Dose charts</Link>
          </li>
            <li className='py-2' style={{ display:'flex', maxWidth:'220px'}} >
            <Link className={splitLocation[1] === "vaccine-schedule-and-information" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/vaccine-schedule-and-information">Vaccine Schedule and Information</Link></li>
            <li className='pb-2' style={{ display:'flex', maxWidth:'220px'}}>
            <Link className={splitLocation[1] === "insurance-and-financial-information" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/insurance-and-financial-information">Insurance and Financial Information</Link>
            </li>
            <li style={{ display:'flex', maxWidth:'220px' }}>
            <Link className={splitLocation[1] === "blog" ? "text-decoration-none hover-sec-head bg-white text-ss-primary p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri" : " text-decoration-none hover-sec-head bg-white text-ss-black p-left-header fs-ss-15 text-raleway py-1 ps-md-4 ps-5 fw-600 text-hover-pri"} to="/blog">Blog</Link></li>

    </ul>
		</li>
        <li className="nav-item p-left-header text-ss-black text-raleway fs-ss-16 fw-700 text-hover-pri">
          <Link className={splitLocation[1] === "contact-us" ? "nav-link text-ss-primary active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold" : "nav-link text-ss-black active text-raleway fs-ss-16 fw-600 text-hover-pri text-bold"} to="/contact-us">Contact Us</Link>
        </li><span className='px-md-2'/>
      </ul>
    </div>
</nav>
</div>
</section>
    </>
  );
}

export default MainHeader;


