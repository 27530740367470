import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/ads/Pediatric-Clinic-near-Albertville-pic1.webp'

const AlbertvilleSickVisit = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <Row className=''>
                <Col md='8' className='py-md-4'>
                    <div className=''>
                        <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                            Pediatric Sick Visits Near Albertville
                        </text>
                    </div>

                    <div className='py-2'>
                        <text className='fs-ss-22-2 fw-700 text-raleway text-ss-black lh-1-2'>
                            You can rely on us
                        </text>
                    </div>


                    <div className='py-2'>
                        <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black '>
                            Children are sick rather frequently, and because we care about them, we will be there for them when they need us. Sometimes it can be hard to decide whether your child’s condition calls for a trip to the doctor. We suggest you to trust your instincts and seek medical advice from a pediatrician whenever you’re unsure. Our team of knowledgeable practitioners will evaluate your child’s symptoms, including rashes, sore throats, ear infections, etc., and then help treat your sick child. Understand that some symptoms call for immediate attention; don’t delay. Call us or stop by if your child exhibits any of the following ailments or peculiar symptoms.
                        </text>
                    </div>
                   
                    <div className=''>
                        {/* <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            Call us or come for a visit if your child has any of the conditions below or something out of routine:
                        </text> */}
                        <ul style={{ padding:'16px', listStyleType:'square'}} className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            <li className=''>A fever between 104.0°F and 105.0°F</li>
                            <li className=''>A fever that persists for more than three days in a row, whether an infection is clearly the cause or not</li>
                            <li className=''>A fever and a sore throat that have persisted for more than 24 to 48 hours</li>
                            <li className=''>Your child gets a rash, becomes lethargic, refuses to eat, or exhibits other symptoms including respiratory problems</li>
                            <li className=''>Dehydration symptoms in children can include a dry mouth, less wet diapers, and more frequent urination</li>
                        </ul>
                    </div>
                </Col>

                <Col md='4' className='pt-md-5'>
                    <div className='pt-md-5 pt-'>
                        <img src={Img1} alt='Sick Visit Boaz' height='auto' width='100%' className=''  />
                    </div>
                </Col>
            </Row>

            <div className='pt-4 pt-md-0'>
                <div className='pb-md-3 pb-2'>
                    <text className='fs-ss-28-2 text-raleway text-ss-black fw-700 lh-1-2'>
                        What to Expect at the Pediatric Clinic Near Albertville?
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Your healthcare professional will talk to you about your child’s symptoms, your family’s medical history, and any other information that might be important in determining your child’s condition. Next would be the physical examination, which involves measuring the child’s temperature, monitoring their breathing and heartbeat, and looking for any indications of an infection.
                    </text>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AlbertvilleSickVisit