import React from 'react'
import Img1 from '../../../assets/images/boaz/Pediatrics-Clinic-Boaz-Banner-v1.webp'

const BoazMain = () => {
  return (
    <>
        <div className='d-block d-md-none d-xl-block'>
    <div id='website-responsive' className='bg-img ' style={{ backgroundImage:`url(${Img1})` ,height:'', width:'100%'}}>
        <div className='boaz-text-padding bg-img-h'>
        <div className='boaz-max-width'>
                <text className='fs-ss-44 text-oswald fw-800 lh-1-2 text-ss-black'>
                    Pediatric Clinic Near Boaz
                </text>
            </div>
        </div>
    </div>    
    </div>
    <div className='d-none d-md-block d-xl-none'>
    <div id='website-responsive' className='bg-img py-5 ps-5' style={{ backgroundImage:`url(${Img1})` ,height:'', width:'100%'}}>
        <div className='py-5 ps-5'>
        <div className='boaz-max-width'>
                <text className='fs-ss-44 text-oswald fw-800 lh-1-2 text-ss-black'>
                    Pediatric Clinic <br /> Near Boaz
                </text>
            </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default BoazMain