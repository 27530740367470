import React from 'react'
import { Phone } from 'react-bootstrap-icons'
import { Button, Col, Row } from 'reactstrap'

const HomeWeAreHere = () => {
  return (
    <div id='website-responsive' style={{ padding:''}}>
    <div className='padding3xem'>
        <Row className='reverse-section justify-content-center gy-4'>
            <Col md='11' xl='6' >
                <div className='flex-center mt-3' style={{ }}>
                    <img src='home/Home-page-pic3-v1.webp' alt='health care' height='' width='' style={{ border:''}} className='image-width' />
                </div>
            </Col>

            <Col md='11' xl='6' style={{}} className=''>
                <div className='ms-0 mb-1 mt-2'>
                    <h3 style={{ letterSpacing:'0.9px'}} className='text-raleway fw-800 fs-ss-30-1 text-ss-primary p-0'>
                        We Are Here For You
                    </h3>
                </div>
                {/* <Row> */}
                    <Col>
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{  backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text' }} >
                           <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1 mx-md-2 mx-1 my-0'> Well Child Care</text>
                        </Button>
                        {/* </Col>
                        <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                            <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Same-Day Sick Visits</text>
                        </Button>
                        {/* </Col> */}

                        {/* <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Laboratory Tests</text>


                        </Button>
                    {/* </Col> */}
                {/* </Row> */}

                {/* <Row>
                <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }} >
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Vision Screening</text>


                        </Button>
                        {/* </Col> */}

                        {/* <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Hearing Screening</text>



                        </Button>
                        {/* </Col> */}

                        {/* <Col > */}

                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Developmental Screening</text>



                        </Button>
                        {/* </Col> */}

                        
                {/* </Row> */}
                {/* <Row>
                 <Col  > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }} >
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Immunizations</text>

</Button>
                        {/* </Col> */}

                        {/* <Col xs='5' > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                           <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'> ADHD </text>

</Button>
                        {/* </Col> */}
                        
                        {/* <Col xs='3'> */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'> Sports Physicals </text>




                        </Button>

                    {/* </Col> */}
                {/* </Row> */}
                {/* <Row> */}
        {/* <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }} >
                         <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1 mx-md-2 mx-1'> Mental Health </text>




                        </Button>
                        {/* </Col> */}

                        {/* <Col > */}
                        {/* <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        <text className=''> Mental Health </text>


                        </Button> */}
                        {/* </Col> */}

                        {/* <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>Referral Coordination</text>



                        </Button>
                    {/* </Col> */}
                {/* </Row> */}
                {/* <Row> */}
                            {/* <Col xs='5' > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }} >
                        <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'> Behavior Evaluation</text>



                        </Button>
                        {/* </Col> */}

                        {/* <Col > */}
                        {/* <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        Behavior Evaluation


                        </Button> */}
                        {/* </Col> */}

                        
                {/* </Row> */}

                {/* <Row> */}
                    {/* <Col > */}
                    {/* <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'  }}>
                        Flu Vaccines


                        </Button> */}
                    {/* </Col> */}

                    {/* <Col > */}
                        <Button className='rounded20 fs-ss-17-2 mt-3 me-2 text-roboto' style={{ backgroundColor:'white', color:'#4a4a4a',  border:'1px solid #DADADA', cursor:'text'   }}>
                         <text className='fs-ss-17-2 text-roboto mx-md-2 mx-1'>   And More! </text>
                        </Button>
                    {/* </Col> */}
                {/* </Row> */}

                <div className='flex-center1 pt-md-3'>
                <a href='tel:+12562021111' style={{ textDecoration:'none'}}>
                <Button className='btn-ss-secondary mt-4 ms-2 rounded20' style={{boxShadow:'0px 2px 10px 0px rgba(0, 0, 0, 0.25)'}}>
                <div className='flex-center'>
                    <Phone  className='me-1 fs-ss-18 text-roboto me-2'/>
                    <text className='fw-500 fs-ss-18 text-roboto'> (256) 202 1111 </text>
                </div>
                </Button>
                </a>
                </div>
                </Col>
            </Col>
        </Row>
        </div>
    </div>
  )
}

export default HomeWeAreHere