import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/arab/Pediatric-Clinic-near-Arab-pic3.webp'

const ArabValleyPsychiatry = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <div className=''>
                <Row>
                    <Col md='8' className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
                        <div className='pe-md-5'>
                            <div className=''>
                                <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                    Valley Psychiatry Arab, Al
                                </text>
                            </div>

                            <div className='py-2 '>
                                <text className='fs-ss-22-2 fw-700 text-raleway text-ss-black lh-1-2'>
                                    Delivering care to heal
                                </text>
                            </div>

                            <div className='py-2 pe-md-5'>
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    Everyone goes through times of stress, sadness, grief, and conflict, and you might need additional support as you move through various life phases of growth. Some people may experience one or more difficult and painful life transition phases more so than others. Such imbalances can have a serious negative impact on your family by generating emotional and behavioral disturbances. Be assured throughout these times. Help is available! It implies that you require teamwork between professionals to address the problems. The care team at Valley Pediatric therapy clinic includes mental health professionals who offer counselling to help patients get over a variety of challenges they can encounter in daily life.
                                </text>
                            </div>

                            <div className='pe-md-5' >
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    We provide complete psychiatric treatments through collaborative care for mood and mental disorders such as OCD, PTSD, ADHD, Bipolar Disorder, and Anxiety. We make certain to offer age-appropriate treatment to adults, teenagers, and kids whilst comprehending their medical needs and requirements.
                                </text>
                            </div>
                        </div>
                    </Col>

                    <Col  md='4' className='pt-md-3' style={{ display:'flex', justifyContent:'end'}}>
                        <div className='pt-md-5'>
                            <img src={Img1} alt='Valley' height='auto' width='100%' />
                        </div>
                    </Col>
                </Row>

                <div className='pt-4 pt-md-0'>
                <div className='pb-md-3 pb-2'>
                    <text className='fs-ss-28-2 text-raleway text-ss-black fw-700 lh-1-2'>
                        How it works?
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Therefore, it’s time to seek professional care as soon as possible if your child is exhibiting symptoms of a mental health disorder, such as an inability to control emotions, deviations from age-appropriate thinking or behavior, persistent sadness, changes in sleeping or eating habits, or a decline in academic performance.
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        For a child’s evaluation, diagnosis, intervention, and treatment plan with their parents or guardians present, get in touch with Valley Psychiatry.
                    </text>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ArabValleyPsychiatry