import React from 'react'
import { Col, Row } from 'reactstrap'

const AboutMeetTheProviders = () => {
  return (
    <div id='website-responsive'>
        <div className='pt-md-5 pt-5'>
            <div className=''>
                <h3 className='fs-ss-36-1 fw-700 text-raleway'>
                    <center>
                        Meet The Providers
                    </center>
                </h3>
            </div>

            <div className='pt-2'>
                <h4 className='fs-ss-26-3 fw-700 text-raleway text-ss-primary lh-1-2'>
                    <center>
                        The Caring Hearts Behind Your Child's Healthcare
                    </center>
                </h4>
            </div>
            </div>

            <div className='py-md-5 pt-md-3 pt-0 py-3' >
                <Row id='amber-lewis' className='py-5 left-padding7rem right-padding7rem justify-content-center'>
                    <Col md='11' xl='8' className=''>
                        <div>
                            <h4 className='fs-ss-34 text-raleway fw-700'>
                                Amber Lewis
                                <hr className='hr-style' style={{ width:'60px', marginTop:'0px', marginBottom:'4px'}}/>
                            </h4>
                        </div>

                        <div className='pb-3'>
                            <h5 className='text-ss-primary text-raleway fw-600 fs-ss-22-1'>   
                                CRNP, CPNP-FC
                            </h5>
                        </div>

                        <div>
                            <text className='fs-ss-16-2 text-roboto'>
                                Although Mrs. Amber grew up in Oregon, she fell in love with Alabama while attending Jacksonville State University for a Bachelor’s degree in nursing. After graduating, she worked as a nurse on an adult medical-surgical floor. However, Amber’s dedication to her occupation helped her attain her dream job as a pediatric nurse at Children’s of Alabama. There, she spent four years in the Bone Marrow Transplant and Hematology/ Oncology units caring for the young patients with compassion and devotion. Along with providing emotional and physical labor to ensure that these children were comfortable and healthy, Amber also pursued a Master’s degree in nursing at the same time. Her devotion to her craft of making the little ones feel better in whatever capacity she can makes her one of the best pediatric healthcare providers in the Guntersville area.   
                                <br/><br/>
                                Amber has been a pediatric nurse practitioner for half a decade and has been lending Valley Pediatrics her sought-after skills for two of those years. In this time, she has attentively catered to the medical needs of children from Etowah, Jackson, and Marshall counties. Fortunately, her care is now available for all surrounding areas of Guntersville. When she is not in the office, Amber can be found spending some quality time with her husband, Cameron, and her two younglings, Finley and Nate. She is a woman of many interests who finds joy in reading, swimming, going to the beach, and designing attires for a smocked children’s clothing company. 
                            </text>
                        </div>
                    </Col>

                    <Col md='7' xl='4' className='pt-md-5 pt-3 '>
                        <img src='/about-us/Amber-Lewis-pic-v1.webp' alt='Providers' width='100%' height='auto' className='about-p-img' />
                    </Col>
                </Row>

                <Row id='bethany-nemati' className='pt-4 gy-5 left-padding7rem right-padding7rem justify-content-center reverse-section pb-md-5 ' style={{ backgroundColor:'#eaeaea' }}>

                <Col md='7' xl='4' className=''>
                    <div className='' style={{ display:'flex', justifyContent:'center' }}>
                        <img src='/about-us/Bethany-Nemati-pic-v2.webp' alt='Providers' width='65%' height='auto' className='' />
                    </div>
                    </Col>

                    <Col md='11' xl='8' className=' '>
                        <div>
                            <h4 className='fs-ss-34 text-raleway fw-700'>
                                Bethany Nemati
                                <hr className='hr-style' style={{ width:'60px', marginTop:'0px', marginBottom:'4px'}}/>
                            </h4>
                        </div>

                        <div className='pb-3'>
                            <h5 className='text-ss-primary text-raleway fw-600 fs-ss-22-1'>   
                                Pediatric Specialist
                            </h5>
                        </div>

                        <div>
                            <text className='fs-ss-16-2 text-roboto'>
                                Bethany Nemati spent her childhood in Harvest and went to Huntsville for higher studies, where she received her degree in mathematics from the University of Alabama. But her passion for serving the community drove her to the University of the Alabama School of Medicine, and Prisma Health Pediatric Residency in Columbia, SC. Committed to improving the health, happiness, and well-being of infants, children, and adolescents, she is now at Valley Pediatric. And we are privileged to have her on board.
                                <br/><br/>
                                She strongly believes it’s never too late to make a positive difference to a child, and with the right tools and knowledge, pediatricians can help shape children’s health for life. So, she makes it a mission to make every patient’s visit a little easier and more fun. She also maintains her work-life balance with perfection and enjoys spending time hiking, gardening, and cooking with her family. Due to the fact that they now have two cats and a reptile collection, their family always has something fun to do.
                            </text>
                        </div>
                    </Col>

                   
                </Row>


                <Row id='keaton-aldrup' className='pt-5 pb-md-0 pb-3 left-padding7rem right-padding7rem justify-content-center'>
                    <Col md='11' xl='8' className=''>
                        <div>
                            <h4 className='fs-ss-34 text-raleway fw-700'>
                                Keaton Aldrup
                                <hr className='hr-style' style={{ width:'60px', marginTop:'0px', marginBottom:'4px'}}/>
                            </h4>
                        </div>

                        <div className='pb-3'>
                            <h5 className='text-ss-primary text-raleway fw-600 fs-ss-22-1'>   
                                MHNP    
                            </h5>
                        </div>

                        <div>
                            <text className='fs-ss-16-2 text-roboto'>
                                Mr. Keaton Aldrup is a Psychiatric Mental Health Nurse Practitioner who specializes in treatment of mental health issues. He graduated from the University of Alabama Huntsville in 2013 with a BSN and later attended the University of Alabama at Birmingham in 2017 with a MSN. Mr. Aldrup has experience treating a multitude of conditions including but not limited to mood disorders, ADHD, anxiety disorders, schizophrenia, PTSD and OCD.
                                <br/><br/>
                                He treats patients of all ages and has experience in both inpatient and outpatient settings. He has worked for several years as an inpatient psychiatric nurse, working with severely mentally ill adult and adolescent patients prior to becoming a nurse practitioner. Mr. Aldrup primarily provides mental health evaluations and medication management services.
                            </text>
                        </div>
                    </Col>

                    <Col md='7' xl='4' className='pt-md-5 pt-3   '>
                        <img src='/about-us/Keaton-Aldrup-pic-v1.webp' alt='Providers' width='89%' height='auto' className='aout-p-img' />
                    </Col>
                </Row>
            </div>
    </div>
  )
}

export default AboutMeetTheProviders