import React from 'react'

const SickVisitsRoadToWellness = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4 pb-5'>
            <div className='pb-2'>
                <h2 className='fs-ss-32 text-raleway text-ss-primary fw-700 lh-1-2'>
                    The Receptive Road To Wellness
                </h2>
            </div>

            <div className=''>
                <p className='fs-ss-16 text-roboto lh-1-8'>
                    <a href='/' className='text-ss-primary' style={{ textDecoration:'none'}} >Valley Pediatrics </a> offers a wide variety of in-office diagnostic testing for the treatment of your children. We provide send-out laboratory testing as needed, constantly cooperating with local imaging centers and hospital labs to obtain the results as soon as possible. Our expertise, proactivity, and convenience are what make us the best clinic for pediatric sick visits Guntersville has to offer.   
                    <br/><br/>
                    Furthermore, our pediatric care providers are specifically trained in specialized diagnostic skills and pediatric examinations of any illnesses that your child may have. We believe in the responsible use of antibiotics, so our providers ensure that they are necessary before prescribing them. We know that viruses actually cause most childhood illnesses, and antibiotic treatments are useless against them and can even cause unnecessary issues for the child in the long run.   
                </p>
            </div>

            <div className='pb-2 pt-md-4 pt-4'>
                <h3 className='fs-ss-32 text-raleway text-ss-primary fw-700 lh-1-2'>
                A Child’s Sickness Does Not Come Knocking

                </h3>
            </div>

            <div className=''>
                <p className='fs-ss-16 text-roboto lh-1-8'>
                    It enters unannounced and refuses to leave without a little extra help. It certainly will not wait for your convenience before showing up. Here at Valley Pediatrics, we understand how urgent ill health can be when the inflicted are our children. That is why we strive to provide quick and accessible health care for your ailing little one. We aim to help you deal with the unwanted visitor as soon as possible so that your child does not have to suffer needlessly.
                </p>
            </div>
        </div>

    </div>
  )
}

export default SickVisitsRoadToWellness