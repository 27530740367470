import React from 'react'

const WellVisitFirstChoice = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 p-top-bottom2rem'>
            <div className='pb-md-3 pb-2'>
                <h2 className='fs-ss-32 text-raleway fw-700 text-ss-primary lh-1'>
                    Make Your Child’s Wellness The First Choice
                </h2>
            </div>

            <div className=''>
                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                    When it is about your child’s healthcare, pediatric well visits in Guntersville are the best preventative measures possible. The regular well visits serve to familiarize you with your child’s physical health to ensure that no unexpected development goes unchecked. They are also considered crucial so that all urgent or ongoing health concerns are addressed professionally as soon as possible. These visits can be timed to assess whether the young individuals are growing appropriately for their age and reaching all their developmental milestones or not. Our providers run physical exams with age-appropriate diagnostic screenings tools or thorough questionnaires and hearing and vision assessments according to requirements.
                </p>
            </div>

            <div className='pt-2'>
                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                    Thus, well visits improve the quality of life for children by ensuring that they are up to date in all recommended immunizations, are physically active, and in optimal internal condition for a healthy and happy childhood. What’s more? We are there with you at every step, even when it is about completing any health-related paperwork your children may need.
                </p>
            </div>
        </div>
    </div>
  )
}

export default WellVisitFirstChoice