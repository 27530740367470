import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import ContactUsMain from '../components/contact-us/ContactUsMain'
import ContactUsInfo from '../components/contact-us/ContactUsInfo'
import ContactUsClinicHours from '../components/contact-us/ContactUsClinicHours'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const ContactUspage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>
        <HelmetProvider>
            <Helmet>
            <title>Contact Best Healthcare for Your Child in Guntersville &ndash; Valley Pediatrics</title>
            <meta name="description" content="Now the best mental and physical healthcare for your child in Guntersville is just a call away. Contact now to make an appointment!" />
            <link rel="canonical" href="https://www.valley-pediatrics.com/contact-us/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Contact Best Healthcare for Your Child in Guntersville &ndash; Valley Pediatrics" />
            <meta property="og:description" content="Now the best mental and physical healthcare for your child in Guntersville is just a call away. Contact now to make an appointment!" />
            <meta property="og:url" content="https://www.valley-pediatrics.com/contact-us/" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2022-06-08T20:58:09+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="2 minutes" />
            </Helmet>
        </HelmetProvider>
        <TopHeader/>
        <MainHeader/>
        <ContactUsMain/>
        <ContactUsInfo/>
        <ContactUsClinicHours/>
        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default ContactUspage