import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/ads/Icon1.png'
import Img2 from '../../../assets/images/ads/Icon2.png'
import Img3 from '../../../assets/images/ads/Icon3.png'

const AlbertvilleChildCare = () => {
  return (
    <div id='website-responsive'>
    <div className='left-padding7rem1 right-padding7rem1 py-md-5'>
      <div className='pt-3 pb-2'>
            <text className='fs-ss-32-1 text-raleway fw-700 lh-1 text-ss-black'>
                The Ideal Child Care for You
            </text>  
      </div>

      <div className=''>
            <text className='fs-ss-16-2 text-roboto lh-1-8'>
                <Link style={{ textDecoration:'none'}} className='text-ss-primary' to='/' >Valley Pediatrics </Link> is a family pediatric clinic located near Albertville (a 15-minute drive from Albertville to our Clinic). Our care team is made up of the best pediatric doctors and medical experts who are passionate about making sure that your kids stay well so they may play, grow, learn, and pursue their goals. Our pediatricians and nurses see to it that children receive the comprehensive care they need to develop for this.
            </text>  
      </div>

      <div className=''>
            <text className='fs-ss-16-2 text-roboto lh-1-8'>
                We always find a time that works for you, whether it’s an emergency visit for a sick child or a regular well-child checkup. We follow the rule of “no patients waiting for appointments” and provide quick, easy access to excellent pediatric care. We believe it’s critical to combine clinical expertise with a gentle, comforting attitude when it comes to your child’s health and wellness.
            </text>  
      </div>


      <div className='pt-5 pb-3 '>
            <text className='fs-ss-32-2 text-raleway fw-700 text-ss-black lh-1-2'>
                Providing Care at Every Turn
            </text>  
      </div>

      <div className='pb-2'>
            <text className='fs-ss-16-2 text-roboto lh-1-8'>
                At Valley Pediatrics, you can receive the best care and an opportunity to network with the best medical professionals all under one roof. We are here to help you at every step of your journey to better health by making healthcare more dependable, practical, and accessible.
            </text>  
      </div>

      <div className='py-5'>
            <Row className=''>
                <Col sm='12' md='4' className='pb-4'>
                    <div className='grid-center'>
                    <div className='flex-center pb-3'>
                    <img src={Img1} alt='Sick visit' height='auto' width='90px'  />
                    </div>
                    <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Same Day Sick Visits</text>
                    </div>
                </Col>

                <Col sm='12' md='4' className='py-4 py-md-0'>
                    <div className='grid-center'>
                    <div className='flex-center pb-3'>
                    <img src={Img2} alt='Sick visit' height='auto' width='90px'  />
                    </div>
                    <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Most Insurances Accepted</text>
                    </div>
                </Col>

                <Col sm='12' md='4' className='py-4 py-md-0'>
                    <div className='grid-center'>
                    <div className='flex-center pb-3'>
                    <img src={Img3} alt='Sick visit' height='auto' width='90px'  />
                    </div>
                    <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Accepting New Patients</text>
                    </div>
                </Col>
            </Row>
        </div>


    </div>
</div>
  )
}

export default AlbertvilleChildCare