import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'
import Image1 from '../../assets/images/ads/About-Middle-Section-bg-webp.webp'
const AdsPageCallNow = () => {
  return (
    <div id='website-responsive' className='bg-img' style={{ backgroundImage:`url(${Image1})`, height:'100%', width:'100%'}}>
        <div className='py-5'>
            <div className=''>
                <text className='fs-ss-28-2 text-montserrat fw-600 text-ss-black lh-1' style={{ letterSpacing:'-1px'}}>
                    <center>
                        Make the right choice for your
                    </center>
                </text>
            </div>

            <div className=''>
                <text className='fs-ss-58 text-montserrat fw-800 text-ss-primary' style={{ letterSpacing:'-1px'}}>
                    <center>
                        Child's Health
                    </center>
                </text>
            </div>

            <div className='pt-5'>
                <center>
                <a href='tel:+12562021111'>
                <Button className='btn-ss-primary' style={{ borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)', padding:'8px 30px'}}>
                    <div className='flex-center'>
                    <TelephoneFill className='me-2 fs-ss-22' />
                    <text className='fs-ss-22 text-roboto fw-600'>
                        (256) 202-1111
                    </text>
                    </div>
                </Button>
                </a>
                </center>
            </div>
        </div>
    </div>
  )
}

export default AdsPageCallNow