import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import AboutMain from '../components/about-us/AboutMain'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import AboutEncourageGoodHealth from '../components/about-us/AboutEncourageGoodHealth'
import AboutChildHealth from '../components/about-us/AboutChildHealth'
import AboutMeetTheProviders from '../components/about-us/AboutMeetTheProviders'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const AboutPage = () => {

  useEffect(() => {
      window.scrollTo(0, 0);
  },[])
  
  return (
    <div>

      <HelmetProvider>
            <Helmet>
            <title>About Valley Pediatric Clinic &ndash; Where We Care for Your Children</title>
            <meta name="description" content="Make attentive healthcare for you and your family a priority, because it is to us! Visit Valley Pediatric Clinic to give your child the comfort of care!" />
            <link rel="canonical" href="https://www.valley-pediatrics.com/about" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="About Valley Pediatric Clinic &ndash; Where We Care for Your Children" />
            <meta property="og:description" content="Make attentive healthcare for you and your family a priority, because it is to us! Visit Valley Pediatric Clinic to give your child the comfort of care!" />
            <meta property="og:url" content="https://www.valley-pediatrics.com/about" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2023-05-03T10:53:13+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
            
            </Helmet>
      </HelmetProvider>
        <TopHeader/>
        <MainHeader/>

        <AboutMain/>
        <AboutEncourageGoodHealth/>
        <AboutChildHealth/>
        <AboutMeetTheProviders/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default AboutPage