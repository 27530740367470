import React from 'react'
import {Link} from 'react-router-dom'

const Faqs = () => {

  return (
    <>
    <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <div className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">

      <span className='fs-ss-16 text-black text-poppins p-2' style={{fontWeight:"600"}}>
        Which insurance does Valley Pediatrics accept?
        </span>

      </button>
    </div>
    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body"><span className='fs-ss-16 text-poppins'>
        We accept Medicaid, Blue Cross, Tricare, United, and others. <br/>
For more information, visit: <Link style={{ textDecoration:'none' }} to='/insurance-and-financial-information' className='text-ss-primary'> insurance and financial information </Link> page or call us: <a href='tel:+12562021111' style={{ textDecoration:'none'}} className='text-ss-primary'><text> (256) 202 1111. </text> </a>

</span></div>
    </div>
  </div>
  <div className="accordion-item">
    <div className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">

      <span className='fs-ss-16 text-black text-poppins p-2' style={{fontWeight:"600"}}>

Why should I pick Valley Pediatrics for my Child’s Care?

</span>

      </button>
    </div>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body"><span className='fs-ss-16 text-poppins'>
        We offer a state-of-the-art facility that is staffed with highly trained healthcare practitioners who are dedicated to ensuring your child’s well-being is up to par. Our team does everything possible to give your little one a slot with our same-day appointments for devoted medical care.
        </span>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <div className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">

      <span className='fs-ss-16 text-black text-poppins p-2' style={{fontWeight:"600"}}>

What is the Valley Medical Vaccine Policy?

</span>      </button>
    </div>
    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">                        <span className='fs-ss-16 text-poppins'>
                        Our policy follows the AAP recommendations and CDC schedule for vaccinations for your children. We do not entertain accepting patients who do not vaccinate.
                        </span></div>
    </div>
  </div>  <div className="accordion-item">
    <div className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
      <span className='fs-ss-16 text-black text-poppins p-2' style={{fontWeight:"600"}}>

What is the Valley Pediatrics Vaccination Schedule for children?                    </span>      </button>
    </div>
    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">                    <span className='fs-ss-16 text-poppins'>
                    We have a detailed vaccination schedule for your child listed here:<Link to='/vaccine-schedule-and-information' className='text-ss-primary' style={{ textDecoration:'none'}}> Vaccine Schedule and Information </Link>
                    </span></div>
    </div>
  </div>  <div className="accordion-item">
    <div className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      <span className='fs-ss-16 text-black text-poppins p-2' style={{fontWeight:"600"}}>

How can I contact the Poison Control Hotline?

</span>
      </button>
    </div>
    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">                    <span className='fs-ss-16 text-poppins'>
                    If you suspect your child has ingested poison, call the Poison Control Hotline number <a href='tel:+1(800)2221222' className='text-ss-primary' style={{ textDecoration:'none'}}> 1 (800) 222 1222</a>. Be sure to contact them right away. Hotline help is confidential, expert, and free.         
                    </span></div>
    </div>
  </div>  <div className="accordion-item">
    <div className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
      <span className='fs-ss-16 text-black text-poppins p-2' style={{fontWeight:"600"}}>

What is the Visit Schedule for Well-Children?

</span>
      </button>
    </div>
    <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">                    <span className='fs-ss-16 text-roboto'>
                    <text className='fs-ss-16 text-roboto fw-700'>The visit schedule for well children is as follows:</text><br/><br/>
                    <div className='d-flex pb-4'>
                      <div className='pe-5'>
                       <li> Right after birth </li>
                       <li> 1 month </li>
                       <li> 2 months </li>
                       <li> 4 months </li>
                       <li> 6 months </li>
                       <li> 9 months </li>
                       <li> 12 months </li>
                       <li> 15 months</li>
                       <li> 18 months </li>
                        </div>
                        <div>
                       <li> 2 years </li>
                       <li> 3 years </li>
                       <li> 4 years </li>
                       <li> 5-10 years </li>
                       <li> 11 years </li>
                       <li> 12-15 years </li>
                       <li> 16 years </li>
                       <li> 17-18 years</li>
                       </div>
                        </div>
                        For more information, visit; <Link style={{ textDecoration:'none' }} to='/well-visits' className='text-ss-primary'> well visits </Link> page.
                    </span></div>
    </div>
  </div>
</div>
    </>
  )
}

export default Faqs