import React from 'react'
import TopHeader from '../../components/layout/TopHeader'
import MainHeader from '../../components/layout/MainHeader'
import BoazMain from '../../components/city-page/boaz-city-page/BoazMain'
import Footer from '../../components/layout/Footer'
import CopyRights from '../../components/layout/CopyRights'
import BoazChildCare from '../../components/city-page/boaz-city-page/BoazChildCare'
import BoazGateway from '../../components/city-page/boaz-city-page/BoazGateway'
import BoazSickVisits from '../../components/city-page/boaz-city-page/BoazSickVisits'
import BoazWellVisit from '../../components/city-page/boaz-city-page/BoazWellVisit'
import BoazValleyPsychiatry from '../../components/city-page/boaz-city-page/BoazValleyPsychiatry'
import BoazPediatricHealthcare from '../../components/city-page/boaz-city-page/BoazPediatricHealthcare'
import BoazWeAreHere from '../../components/city-page/boaz-city-page/BoazWeAreHere'
import BoazLocation from '../../components/city-page/boaz-city-page/BoazLocation'

const BoazCityPage = () => {
  return (
    <div>
        <TopHeader/>
        <MainHeader/>

        <BoazMain/>
        <BoazChildCare/>
        <BoazGateway/>
        <BoazSickVisits/>
        <BoazWellVisit/>
        <BoazValleyPsychiatry/>
        <BoazPediatricHealthcare/>
        <BoazWeAreHere/>
        <BoazLocation/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default BoazCityPage