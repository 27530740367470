import React from 'react'

const PsychiatryOptimalHealth = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding6rem right-padding6rem p-bottom'>
            <div className='pb-4'>
                <h2 className='fs-ss-32 fw-700 text-raleway text-ss-primary lh-1'>
                    The Path Optimal Health & Well-Being
                </h2>
            </div>

            <div className=''>
                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                    The human mind is a complex space; sometimes, it needs some help from the outside to decode everything. That doesn’t mean you are ill; it just means there is always room to improve. At Valley Medical Clinic, a whole division is dedicated to mental and behavioral health care. Our expert providers specialize in the psychiatric care of you and your child. Whether it is developmental medication for your kids or yourself in your journey as a parent, we have you covered. We ensure care for all aspects of your being because wellness exists in harmony. Start the journey to better mental, physical, and behavioral health for your child and yourself with the best providers at your disposal here at Valley Medical! 
                </p>
            </div>
        </div>
    </div>
  )
}

export default PsychiatryOptimalHealth