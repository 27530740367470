import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const CopyRights = () => {
  return (
    <div id='website-responsive' className='flex-center' style={{ width:'100%', height:'', backgroundColor:'#edeeef', borderTop:'2px solid #FF379F' }}>
        <Row className='pt-2 reverse-section pb-2' style={{ padding:'10px'}}> 
            {/* <Col className='' style={{ textAlign:'', padding:'5px' }}> */}
                <text className='fs-ss-15 text-poppins fw-400' style={{ padding:'' }}>
                    Copyright © 2023  Valley Pediatrics | Powered by <a target='_blank' href='https://simplesolutionz.org/' className='text-ss-primary' style={{ textDecoration:'none'}}> Simple Solutionz </a>
                </text>
            {/* </Col> */}

            {/* <Col className='flex-center' style={{}}>
                <a className='fs-ss-16 text-poppins fw-400' href='/privacy-policy'>
                    Privacy Policy
                </a>
                <text className='mx-2'>|</text>
                <a className='fs-ss-16 text-poppins fw-400' href='/terms-conditions'>
                    Terms & Conditions
                </a>
            </Col> */}
        </Row>
    </div>  
  )
}

export default CopyRights