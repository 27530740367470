import React from 'react'
import { Button, Col, Row } from 'reactstrap'

const HomeLocation = () => {
  return (
    <div id='website-responsive'>
         <Row className='left-padding7rem right-padding7rem pt-5 pb-5 '>
            <Col xs='12' sm='12' md='12' lg='6' className='marginBottom'  style={{ padding:'', paddingTop:''}}>
                 {/* <hr style={{ width:'100px', height:'5px', color:'#d74100', opacity:'1'}}/> */}

            <h3 className='fs-ss-30-1 fw-800 text-ss-primary pb-1 text-raleway' style={{ letterSpacing:'0.9px' }}>
                Where Your Young Thrive
            </h3> 
            <br/>

            <text className='text-roboto fs-ss-16-2 lh-1-8'>
                We help you in your goal to ensure that your child’s health never falls below the peak condition. So, your expectations may be high, but we always work hard to reach further. If you seek attentive and accessible care for your child, then our pediatrics clinic in Guntersville is the place for you!
            </text>
            <br/><br/>

            <div className='flex-center1 pt-md-4'>
            <a target='_blank' rel='noreferrer' href='https://www.google.com/maps?ll=34.352709,-86.299005&z=17&t=m&hl=en-US&gl=US&mapclient=embed&q=Valley+Medical,+1436+Gunter+Ave,+Guntersville,+AL+35976,+United+States'>
            <Button className='btn-ss-secondary btn-hover rounded20' style={{ boxShadow:'0px 2px 10px 0px rgba(0, 0, 0, 0.25)', padding:'8px 15px' }}>
                <text className='fs-ss-18 fw-500'>View on Google Map</text>
            </Button>
            </a>
            </div>
            </Col>

            <Col xs='12' sm='12' md='12' lg='6' className='pb-md-1 pt-md-2' style={{ paddingTop:'', paddingBottom:''}}>
                <div className='elementor-custom-embed'>
            {/* [um-iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d54438.89465737263!2d74.27791584142051!3d31.484837283482378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d31.508994299999998!2d74.34483929999999!4m5!1s0x391901677786d497%3A0xf130ea67c5d5a58e!2shorizon%20med%20ped%20clinic!3m2!1d31.458783999999998!2d74.2875911!5e0!3m2!1sen!2s!4v1689224124038!5m2!1sen!2s" width="100px" height="100px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"] */}
            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3294.1996964518066!2d-86.3046718!3d34.3453954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8889f934d22a25b9%3A0x88839cfbc9bb566c!2sValley%20Medical!5e0!3m2!1sen!2s!4v1691422792929!5m2!1sen!2s" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>            
            </div>
            </Col>
         </Row>
           
    </div>
  )
}

export default HomeLocation