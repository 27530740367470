import React from 'react'

const VaccineScheduleMain = () => {
  return (
    <>
    <div id='website-responsive' className='d-block d-md-none d-xl-block'>
        <div className='bg-img2 about-text-padding' style={{ backgroundImage: 'url("/vaccine/Vaccine-Schedule-Banner-v1.webp")', height:'100%', width:'100%' }}>
            <div className='vaccine-max-width'>
                <h1 className='fs-ss-50 text-oswald fw-900 lh-1 text-black'>
                Vaccine Schedule &  <br/> Information
                </h1>
             </div>
        </div>
    </div>
    <div id='website-responsive' className='d-none d-md-block d-xl-none'>
        <div className='bg-img2 py-5' style={{ backgroundImage: 'url("/vaccine/Vaccine-Schedule-Banner-v1.webp")', height:'100%', width:'100%' }}>
            <div className='vaccine-max-width py-5 ps-5'>
                <h1 className='fs-ss-44 text-oswald fw-900 lh-1 text-black'>
                Vaccine Schedule &  <br/> Information
                </h1>
             </div>
        </div>
    </div>
    </>

  )
}

export default VaccineScheduleMain