import React, { useEffect } from 'react'
import AdsPageHeader from '../components/ads-page/AdsPageHeader'
import AdsPageMain from '../components/ads-page/AdsPageMain'
import AdsPageValleyPediatrics from '../components/ads-page/AdsPageValleyPediatrics'
import AdsPageOurServices from '../components/ads-page/AdsPageOurServices'
import AdsPageAppointment from '../components/ads-page/AdsPageAppointment'
import AdsPageProviders from '../components/ads-page/AdsPageProviders'
import AdsPageCallNow from '../components/ads-page/AdsPageCallNow'

const AdsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>
        <AdsPageHeader/>
        <AdsPageMain/>
        <AdsPageValleyPediatrics/>
        <AdsPageOurServices/>
        <AdsPageAppointment/>
        <AdsPageProviders/>
        <AdsPageCallNow/>
    </div>
  )
}

export default AdsPage