import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'
import Img1 from '../../assets/images/ads/Pediatric-Healthcare-Banner-bg.webp'

const AdsPageMain = () => {
  return (
    <div id='website-responsive' className='bg-img px-md-3' style={{ backgroundImage: `url(${Img1})`, height:'100%', width:'100%'}}>
        <div className='p-top-bottom2rem1'>
            <div className='pb-md-0 pb-3 '>
                <text className='fs-ss-58 fw-800 text-montserrat lh-1' style={{ color:'#292828' }}>
                    <center>
                        Need Pediatric Healthcare
                    </center>
                </text>
            </div>

            <div className='p-left-right'>
                <text className='fs-ss-28 fw-700 text-montserrat lh-1-2' style={{ color:'#292828', letterSpacing:'-1px' }}>
                    <center>
                        Services for your child on same day? Not sure where to go?
                    </center>
                </text>
            </div>

            <div className='pt-md-4 pt-5 pb-md-4 pb-3'>
                <text className='text-ss-primary fs-ss-52-1 fw-800 text-montserrat lh-1-2'>
                    <center>
                        We've got you covered!
                    </center>
                </text>
            </div>

            <div className='pt-4'>
                <center>
                <a href='tel:+12562021111'>
                <Button className='btn-ss-ads-page' style={{ background:'none', borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)', padding:'5px 36px'}}>
                    <div className='flex-center'>
                    <TelephoneFill className='me-2 fs-ss-24'/>
                    <text className='fs-ss-26 text-roboto fw-600'>
                        256-202-1111
                    </text>
                    </div>
                </Button>
                </a>
                </center>
            </div>
        </div>
    </div>
  )
}

export default AdsPageMain