import React from 'react'
import { Table } from 'reactstrap'

const VaccineScheduleYourChildrenNeed = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem p-top-bottom2rem2 pb-4 pb-md-5'>
            <div className='pb-md-4 pb-5'>
                <h3 className='fs-ss-28-2 text-raleway fw-700 text-ss-primary lh-1'>
                    <center>
                        The Shot That Your Children Need!
                    </center>
                </h3>
            </div>

            <div className='left-padding5rem right-padding5rem'>
            <Table striped bordered>
            <tbody className='' style={{ textAlign:'center' }}>
                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>2 MONTH</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>PENTACEL (DTAP, IPV, HIB), PREVNAR (PCV13), HEPATITIS B, ROTAVIRUS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>4 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>PENTACEL (DTAP, IPV, HIB), PREVNAR (PCV13), ROTAVIRUS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>6 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>PENTACEL (DTAP, IPV, HIB), PREVNAR (PCV13), HEPATITIS B, ROTAVIRUS</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>12 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>PROQUAD (MMR AND VARICELLA), HEPATITIS A, PREVNAR (PCV13)</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>15 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>PENTACEL (DTAP, IPV, HIB)</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>18 MONTHS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>HEPATITIS A</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>4 YEARS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>KINRIX (DTAP, IPV) AND PROQUAD (MMR AND VARICELLA)</td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>11 YEARS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>BOOSTRIX (TDAP), GARDASIL (HPV9), MENACTRA (MENINGITIS A,C,W,Y)
                    <br/>-WILL NEED SECOND DOSE OF GARDASIL 6-12 MONTHS AFTER FIRST DOSE
                    </td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>16 YEARS</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>BEXSERO (MENINGITIS B), MENACTRA (MENINGITIS A, C, W, Y)
                    <br/>-WILL NEED SECOND DOSE OF BEXSERO 1 MONTH AFTER FIRST DOSE
                    </td>
                </tr>

                <tr className=''>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>YEARLY</td>
                    <td className='fs-ss-14-1 px-md-3 py-2 '>SEASONAL INFLUENZA VACCINE</td>
                </tr>
            </tbody>
        </Table>
            </div>
        </div>
    </div>
  )
}

export default VaccineScheduleYourChildrenNeed