import React from 'react'

const InsAndFinMain = () => {
  return (
    <>
    <div id='website-responsive' className='d-none d-xl-block'>
    <div className='bg-img3 financial-text-padding' style={{ backgroundImage: 'url("/insurance-financial/Insurance-Banner.webp")', backgroundPosition:'left', height:'100%', width:'100%' }}>
        <div className='financial-max-width text-align-center display-none1'>
            <h1 style={{ }} className='fs-ss-46 text-oswald fw-900 lh-1-2 text-black'>
                <>
                    Insurance and Financial Information
                </>
            </h1>
         </div>
    </div>
</div>
<div id='website-responsive' className='d-block d-xl-none'>
    <div className='bg-img3 text-end py-md-5 pe-3 pe-md-5' style={{ backgroundImage: 'url("/insurance-financial/Insurance-Banner.webp")', backgroundPosition:'left', height:'100%', width:'100%' }}>
        <div className='py-5'>
            <h1 style={{ }} className='fs-ss-44 text-oswald fw-900 lh-1-2 text-black'>
                    Insurance and <br /> Financial Information
            </h1>
         </div>
    </div>
</div>
</>
  )
}

export default InsAndFinMain