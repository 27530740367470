import React from 'react'
import { Col, Row } from 'reactstrap'

const WellVisitPediatrics = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 p-top-bottom2rem1'>
            <Row className='gy-5'>
                <Col md='11' xl='6'>
                    <div className='pb-md-3 pb-1'>
                        <h3 className='fs-ss-32 text-raleway fw-700 text-ss-primary'>
                            Pediatrics Recommend
                        </h3>
                    </div>

                    <div>
                        <p className='fs-ss-16 text-roboto lh-1-8'>
                            The American Academy of Pediatrics recommends a perfectly timed schedule for well-child check-ups for the best time to catch developmental discrepancies. We have also compiled a list of things you can expect from these visits with your child. The chart below contains the information mentioned above. Please check out our parent resource tab for further details about immunization schedules for each visit.
                        </p>
                    </div>
                </Col>
                <Col md='11' xl='6'>
                    <div className='flex-center pt-md-0 pt-4'>
                        <img src="/well-visit/Sick-Visits-children-pic.webp" alt='Covid testing' className='img-w8' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default WellVisitPediatrics