import React from 'react'
import { Facebook, GeoAltFill, Instagram, TelephoneFill } from 'react-bootstrap-icons'

const TopHeader = () => {
  return (
    <div id='website-responsive'  style={{ width:'100%', height:'', backgroundColor:'#64cbcc', position:'', display:'flex', alignItems:'center' }}>
        <div className='top-header-grid-center py-2 top-header-margin-left ' style={{width:'100%', marginLeft:''}}>
        <div className='flex-center1'>
        <a style={{ textDecoration:'none' }} href='tel:+12562021111'>
          <TelephoneFill className='text-ss-primary fs-ss-16'/>
        <text className='px-2 border-end border-2 me-3 text-roboto fs-ss-14-3' style={{ color:'black', fontSize:'', borderColor:'#FF379F' }}> (256) 202 1111 </text>
        </a>
        </div>

        <div className='flex-center1'>
        <a style={{ textDecoration:'none' }} target='_blank' href='https://www.google.com/maps/place/Valley+Medical/@34.3454038,-86.3068594,17z/data=!4m2!3m1!1s0x8889f934d22a25b9:0x88839cfbc9bb566c' >
          <GeoAltFill className='text-ss-primary'/>
        <text className='ps-2 text-black fs-ss-14-3 text-roboto' style={{ color:'', fontSize:''}}>1436 Gunter Ave, Guntersville, AL 35976, US</text>
        </a>
        </div>
        </div>

        <div className='d-none d-lg-block text-end' style={{ width:'30%', display:'', justifyContent:'end', marginRight:'12rem',    }}>
          <a target='_blank' style={{ textDecoration:'none'}} href='https://www.facebook.com/valleypediatrics/'><Facebook className='text-black fs-ss-22 me-3' /> </a>
          <a target='_blank' style={{ textDecoration:'none'}} href='https://www.instagram.com/valleypediatrics1/'><Instagram className='text-black fs-ss-20' /></a>
        </div>
    </div>
  )
}

export default TopHeader