import React from 'react'
import { Table } from 'reactstrap'

const DoseChartsTylenol = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem  p-top-bottom2rem3'>
            <div className='pb-2 pb-md-4'>
                <h2 className='fs-ss-30-3 text-raleway fw-700 text-ss-primary'>
                    <center>
                        TYLENOL (ACETAMINOPHEN) DOSE CHART
                    </center>
                </h2>
        </div>


            <div className='pb-3'>
                <p className='fs-ss-16-2 text-roboto'>
                    <div className='pb-2'>
                    *MAY USE INFANT OR CHILDREN’S TYLENOL (ACETAMINOPHEN) FOR ANY AGE AS ACTIVE INGREDIENT AND CONCENTRATION IS THE SAME
                    <br/>
                    </div>
                    <div className='pb-3'>
                    *WEIGHT-BASED DOSING IS PREFERED OVER AGE-BASED DOSING
                    </div>
                </p>
            </div>

            <div className='pb-md-4'>
                <Table striped style={{ textAlign:'center'}}>
                    <thead>
                        <tr>
                            <th className='fs-ss-14-3 text-roboto'>WEIGHT</th>
                            <th className='fs-ss-14-3 text-roboto'>AGE</th>
                            <th className='fs-ss-14-3 text-roboto'>DOSE</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>6-11 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>0-3 MONTHS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>1.25ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>12-17 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>4-11 MONTHS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>2.5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>18-23 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>12-23 MONTHS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>3.75ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>24-35 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>2-3 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>36-47 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>4-5 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>7.5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>48-59 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>6-8 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>10ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>60-71 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>9-10 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>12.5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>72-95 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>11 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>15ML</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

        <div>
            <div className='pt-md-5 pt-3 pb-2 pb-md-4'>
                <h2 className='fs-ss-30 text-raleway fw-700 text-ss-primary'>
                    <center>
                        MOTRIN (IBUPROFEN) DOSE CHART
                    </center>
                </h2>
            </div>

            <div className='pb-md-5 pb-3'>
                <p className='fs-ss-16-2 text-roboto'>
                <div className='pb-3'>
                *CANNOT BE GIVEN BEFORE 6 MONTHS OF AGE
                </div>

                {/* <br/> */}
                <div className='pb-3'>
                *INFANT AND CHILDREN’S MOTRIN ARE NOT THE SAME ACTIVE INGREDIENT AND CONCENTRATION AND THE FORMULATIONS ARE NOT INTERCHANGEABLE. MUST USE FORM SPECIFIED ON DOSE CHART
                {/* <br/> */}
                </div>
                <div className='pb-3'>
                *WEIGHT-BASED DOSING IS PREFERED OVER AGE-BASED DOSING
                </div>
                </p>
            </div>

            <div>
                <Table striped style={{ textAlign:'center'}}>
                    <thead>
                        <tr>
                            <th className='fs-ss-14-3 text-roboto'>WEIGHT</th>
                            <th className='fs-ss-14-3 text-roboto'>AGE</th>
                            <th className='fs-ss-14-3 text-roboto'>FORMULATION</th>
                            <th className='fs-ss-14-3 text-roboto'>DOSE</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>6-11 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>0-6 MONTHS</td>
                            <td className='fs-ss-14-3 text-roboto p-3 fw-700'>DO NOT GIVE</td>
                            <td className='fs-ss-14-3 text-roboto p-3 fw-800'>DO NOT GIVE</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>12-17 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>6-11 MONTHS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>INFANTS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>1.25ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>18-23 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>12-23 MONTHS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>INFANTS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>1.875ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>24-35 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>2-3 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>CHILDRENS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>36-47 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>4-5 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>CHILDRENS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>7.5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>48-59 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>6-8 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>CHILDRENS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>10ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>60-71 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>9-10 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>CHILDRENS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>12.5ML</td>
                        </tr>

                        <tr>
                            <td className='fs-ss-14-3 text-roboto p-3'>72-95 LBS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>11 YEARS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>CHILDRENS</td>
                            <td className='fs-ss-14-3 text-roboto p-3'>15ML</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
        </div>

    </div>
  )
}

export default DoseChartsTylenol