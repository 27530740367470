import React from 'react'
import { Row } from 'reactstrap'

const ContactUsClinicHours = () => {
  return (
    <>
    <div id='website-responsive' className='bg-img' style={{ backgroundImage:'url("/contact/Contact-us-middle-section-bg.png")', height:'100%', width:'100%', backgroundAttachment:'fixed'}}>
        <div className=''>
            <center>
                <img src='/contact/Working-Hours-pic-1024x689.png' alt='Clinic Hours' className='img-w13 p-3 p-md-5'  />
            </center>
        </div>
        </div>

        <div className='pt-5'>
        <div className='pt-3'>
            <text className='text-raleway text-ss-black fw-700 fs-ss-32'>
            <center>
                Find Us On Google Map
            </center>
            </text>
        </div>
        <Row>
            <div style={{ marginTop:'1rem', marginBottom:'-6px'}}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1647.0991744988046!2d-86.30452668062196!3d34.34542969772766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8889f934d22a25b9%3A0x88839cfbc9bb566c!2sValley%20Medical!5e0!3m2!1sen!2sus!4v1699869092413!5m2!1sen!2sus"  height='500px' width='100%' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </Row>
        </div>
        </>
  )
}

export default ContactUsClinicHours